@charset "utf-8";
/* CSS Document */
* {
	margin: 0;
	padding: 0;
	font-family: Gotham, Helvetica Neue, Helvetica, Arial, " sans-serif";
}
ul {
	list-style: none;
}
a {
	outline: none;
	text-decoration: none;
}
a img {
	outline: none;
}
.d-flex {
	display: flex;
}
.container {
	width: 1140px;
	max-width: 100%;
	margin: auto;
	padding-bottom: 24px;
	padding-top: 24px;
}
.main-login {
	width: 100%;
	position: relative;
}
.line-top {
	height: 4px;
	width: 100%;
	background: rgba(71,140,22,1.00);
}
.logo-img{margin-left: 32px; margin-right: 32px; max-width: calc(100% - 64px)!important;}
main {
	min-height: calc(100vh - 60px);
	width: 100%;
	background-color: #fff;
}
.login-box {
	width: 1140px;
	max-width: 100%;
	height: auto;
	position: relative;
	margin: auto;
}
.login-box .flex-item {
	min-height: 300px;
	position: relative;
	padding: 32px;
	background: #fff;
	flex: 1;
	height: 100%;
}
.img-reponsive {
	max-width: 100%;
}
.center-item {
	display: block;
	margin: auto;
}
.text-box {
	width: 100%;
}
.text-box h2 {
	text-align: center;
	color: rgba(71,140,22,1.00);
	font-size: 36px;
}
.text-box ul {
	margin: auto;
	margin-top: 24px;
}
.text-box ul li {
	text-align: center;
	height: 30px;
}
.login-form {
	background: #fff;
	box-shadow: 0px 1px 6px rgba(0,0,0,0.2);
	border-radius: 4px;
}
.login-header {
	padding-top: 24px;
	padding-bottom: 24px;
	text-align: center;
	background: linear-gradient(90deg, #9ae66f 0%, #00aa4f 100%);
	font-weight: bold;
	color: #fff;
}
.login-header p{font-size: 24px;}
.login-body {
	background: #fff;
	min-height: 100px;
	padding-top: 48px;
	padding-bottom: 24px;
}
.login-body .text-input {
	width: 80%;
	max-width: 80%;
	margin-left: 10%;
	height: 40px;
	border: none;
	border-bottom: 1px solid #999;
	margin-bottom: 24px;
	font-size: 16px;
}
.login-body .text-input:focus {
	outline: none;
	border-bottom: 1px solid rgba(68,144,21,1.00);
	color: rgba(68,144,21,1.00);
}
.btn-login {
	width: 80%;
	height: 50px;
	border-radius: 50px;
	background-image: linear-gradient(90deg, #9ae66f 0%, #00aa4f 100%);
	box-shadow: 0 3px 16px rgba(0, 0, 0, 0.2);
	margin-left: 10%;
	border: none;
	font-size: 16px;
	text-transform: uppercase;
	font-weight: bold;
	color: #fff;
	margin-top: 24px;
	margin-bottom: 24px;
}
.login-footer {
	background: #ededed;
	padding-top: 24px;
	padding-bottom: 24px;
	text-align: center;
}
.login-footer a {
	margin: auto;
	color: rgba(68,144,21,1.00);
}
footer {
	background-image: linear-gradient(90deg, #9ae66f 0%, #00aa4f 100%);
	padding: 30px;
	position: relative;
	display: block;
}
.footer-login .col-12{padding: 24px; color: #fff; font-weight: normal;}
.footer-login .col-12 h3{font-weight: bold;}
.footer-login .col-12 h3,.footer-login .col-12 h4,.footer-login .col-12 h5{ margin-bottom: 16px; font-weight: normal; line-height: 1.5em;}
.phone-number{ border: 1px solid #fff; float: left; vertical-align: middle; margin-right: 16px; margin-bottom: 24px;}
.phone-number span{ display: block; height: 100%; float: left; padding: 8px;}
.phone-number span.sp-white{background: #fff; color: #1B9012; font-weight: bold;}
.footer-login .d-flex .flex-item{flex: 1;}
.text-center{text-align: center;}
.text-white{color: white;}
.pading-24{padding: 24px;}
.copy-right{border-top: 1px solid rgba(255,255,255,0.5);}





/*Responsite*/
@media only screen and (max-width:940px) {
	.footer-login .d-flex{display: flex; flex-direction: column;}
	
}
@media only screen and (max-width:640px) {
	.login-box{display: flex;
  flex-direction: column-reverse;}
	.d-flex{flex-wrap: wrap;}
	.d-flex  .flex-item{ flex: auto !important;}
	footer{padding: 0px;}
	.login-box .flex-item{padding: 16px;}

}

