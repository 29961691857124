@charset "utf-8";
/* CSS Document */
/*Reset csss*/
*{padding: 0; margin: 0;}
a{text-decoration: none;}
a img{outline: none;}

/*Style css*/
.top_menu{height: 80px; width: 100%; box-shadow: 0px 2px 6px rgba(0,0,0,0.2); position: fixed; z-index: 1000; display: flex;}
.space_80{position: relative; width: 100%; height: 80px; z-index: 1;}
.logo_box{position: relative; width: 240px; height: 80px;}
.logo{float: left; margin-left: 30px;}
.menu_box{height: 80px; margin-left: 20px; margin-right: 20px;}
.menu_btn{float: left; margin-top: 24px;}

.react-autosuggest__container{width:100%;}
.react-autosuggest__container,.search_box{height: 32px; float: left; position: relative; flex: 1;}
.search_box img{ position: absolute; margin-top: 3px;}
.react-autosuggest__container .react-autosuggest__input,.search_box .search_txt_input{display: block; float: left; padding-left: 32px; padding-right: 20px; padding-top: 6px; padding-bottom: 6px; border: none; border-bottom: 1px solid #ededed; width: 100%;}
.react-autosuggest__input:focus,.search_txt_input:focus{border: 1px solid #77F900; outline: none;}
.menu_right_top{float: right; display: flex; height: 32px; margin-top: 24px;}
.menu_right_top div{margin-left: 8px; margin-right: 8px;}
.avatar_user{ width: 42px;}
.user_block p{float: left; margin-right: 10px;}
.user_block img{float: left; margin-right: 10px;}

